<template>
    <div id="deliver-detail" class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px"
            style="margin: 14px 36px 4px; float: right">
            <el-form-item label="" prop="deviceName">
                <el-input v-model="queryParams.deviceName" placeholder="请输入设备名称" clearable
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-card style="width: 94%; height: 70vh; margin-left: 3%">
            <el-table v-loading="loading" :data="deviceList">
                <!-- <el-table-column label="id" align="center" prop="id" /> -->
                <el-table-column label="设备名称" align="center" prop="deviceName">
                    <template slot-scope="scope">
                        {{ scope.row.deviceName }}
                        <el-popover placement="right" trigger="hover">
                            <i class="el-icon-info" slot="reference"></i>
                            <div slot style="width: 250px; height: 350px">
                                <img src="@/assets/shitBox.png" style="width: 100%; height: 100%" />
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="N罐累积施肥量" align="center" prop="nSumFertilizerAmount" />
                <el-table-column label="N罐累积施肥次数" align="center" prop="nFertilizationCount" />
                <el-table-column label="P罐累积施肥量" align="center" prop="pSumFertilizerAmount" />
                <el-table-column label="P罐累积施肥次数" align="center" prop="pFertilizationCount" />
                <el-table-column label="K罐累积施肥量" align="center" prop="kSumFertilizerAmount" />
                <el-table-column label="K罐累积施肥次数" align="center" prop="kFertilizationCount" />
                <el-table-column label="累积冲水量" align="center" prop="flush" />
                <el-table-column label="清水冲洗次数" align="center" prop="flushCount" />
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-setting" @click="toManure(scope.row)">施肥
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="getList" style="width: 94%; margin: 8px auto" />
    </div>
</template>

<script>
    export default {
        name: "Device",
        components: {},
        data() {
            return {
                // 遮罩层
                loading: true,
                // 导出遮罩层
                exportLoading: false,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 【请填写功能名称】表格数据
                deviceList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    deviceName: null,
                    productKey: null,
                    nSumFertilizerAmount: null,
                    nFertilizationCount: null,
                    pSumFertilizerAmount: null,
                    pFertilizationCount: null,
                    kSumFertilizerAmount: null,
                    kFertilizationCount: null,
                    flush: null,
                    flushCount: null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {},
            };
        },
        created() {
            this.getList();
        },
        methods: {
            toManure(e) {
                sessionStorage.setItem("deviceName", e.deviceName);
                sessionStorage.setItem("productKey", e.productKey);
                this.$router.replace("controller");
            },
            getList() {
                this.loading = true;
                this.$webAxios
                    .post("/jsh-api/system/device/list", this.queryParams)
                    .then((response) => {
                        response = response.data;
                        this.deviceList = response.rows;
                        this.total = response.total;
                        this.loading = false;
                    });
            },
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.queryParams.productKey = '';
                this.getList();
            },
            resetQuery() {
                this.resetForm("queryForm");
                this.handleQuery();
            },
        },
    };
</script>

<style>
    #deliver-detail .el-form-item {
        margin-bottom: 0;
    }
</style>